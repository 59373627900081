<template>
  <tr class="labour-laws__table__row">
    <td class="labour-laws__table__cell">
      <SkInput
        v-model="labourLawTitle"
        :disabled="true"
      />
    </td>
    <td class="labour-laws__table__cell">
      <SkInput
        v-model="labourLaw.scope"
        :disabled="true"
      />
    </td>
    <td class="labour-laws__table__cell">
      <SkInput
        v-model="labourLaw.key"
        :disabled="true"
      />
    </td>
    <td class="labour-laws__table__cell">
      <SkInput
        v-model="labourLawStatus"
        :disabled="true"
      />
    </td>
    <td class="labour-laws__table__cell">
      <SkDropdown
        ref="dropdown"
        class="cell__dropdown"
        placement="bottom-start"
        y-offset="5px"
        trigger="click"
      >
        <template #anchor>
          <SkIconButton
            icon="KebabIcon"
            data-test="lll_item__kebab-menu"
          />
        </template>
        <template #menu>
          <div class="cell__dropdown-menu">
            <a
              :class="menuButtonClass"
              :data-test="`lll_item__edit-${labourLaw.key}`"
              href="#"
              @click="editAction(labourLaw)"
            >
              <PencilIcon class="dropdown-item__icon" />
              {{ $t("super_admin_js.labour_laws.buttons.edit") }}
            </a>
            <a
              :class="menuButtonClass"
              :data-test="`lll_item__delete-${labourLaw.key}`"
              href="#"
              @click="deleteAction(labourLaw)"
            >
              <TrashCanIcon class="dropdown-item__icon" />
              {{ $t("super_admin_js.labour_laws.buttons.delete") }}
            </a>
          </div>
        </template>
      </SkDropdown>
    </td>
  </tr>
</template>

<script>
import {
  PencilIcon,
  SkDropdown,
  SkIconButton,
  SkInput,
  TrashCanIcon,
} from '@skelloapp/skello-ui';
import { SCOPES } from '../lll-constants.js';

export default {
  name: 'LabourLawListItem',
  components: {
    PencilIcon,
    SkDropdown,
    SkIconButton,
    SkInput,
    TrashCanIcon,
  },
  props: {
    labourLaw: {
      type: Object,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    editAction: {
      type: Function,
      required: true,
    },
    deleteAction: {
      type: Function,
      required: true,
    },
  },
  computed: {
    availableScopes() {
      return SCOPES.map((scopeKey) => ({ id: scopeKey, text: this.$t(`super_admin_js.labour_laws.scopes.${scopeKey}`) }));
    },
    labourLawTitle() {
      return this.$t(`super_admin_js.labour_laws.agreement_legible_name.${this.labourLaw.key}`);
    },
    labourLawStatus() {
      const status = this.labourLaw.enabled ? 'enabled' : 'disabled';
      return this.$t(`super_admin_js.labour_laws.form.labels.status_${status}`);
    },
    menuButtonClass() {
      return {
        'cell__dropdown-item': true,
        'cell__dropdown-item-disabled': this.isLoading,
      };
    },
  },
};
</script>

<style lang='scss' scoped>
.labour-laws__table__row {
  height: 68px;

  td {
    padding-right: 16px;

    &:last-child {
      vertical-align: middle;
      padding-right: 0;
    }
  }
}

.labour-laws__table__cell {
  max-width: 289px;

  // trick to get the last cell to fit button size
  &:last-child {
    width: 1%;
    white-space: nowrap;
  }
}

::v-deep .sk-dropdown__menu {
  z-index: 30;
}

.cell__dropdown-menu {
  position: relative;
  right: 80px;
  background-color: white;
  box-shadow: 0 8px 16px rgba(0, 0, 0, .15);
  border-radius: 2px;
  padding: 10px 0;
}

.cell__dropdown-item {
  display: flex;
  align-items: center;
  color: $sk-black;
  padding: 10px 15px;
  width: 100%;

  &-disabled {
    cursor: not-allowed;
    color: $sk-gray-med-3;
  }

  &:hover {
    background: $sk-gray-light-1;
    border-radius: 3px;
    text-decoration: none;
  }

  .dropdown-item__icon {
    margin-right: 16px;
  }
}
</style>
